import React, { Fragment, useState, useEffect } from 'react';

// Material-ui
import { Button, Box, Grid, Stack, Typography } from '@mui/material';

// Project imports
import UploadDropZone from './UploadDropZone';
import TablePreview from '../../components/Table';
import CustomLoadingScreen from '../../../../ui-components/CustomLoadingScreen';

// Helper Functions
import {
  FileExists,
  UploadFile as UploadFileToS3,
  validateFileSize,
  validateFileType,
} from '../../../../common/HelperFunctions';

// Sub Components
import DownloadFileSample from './DownloadFileSample';

// Steps
import SelectSheetFile from '../SelectSheetFile/SelectSheetFile';

// Third Party
import { useSnackbar } from 'notistack';

const UploadFile = ({
  uploadFileData,
  setUploadFileData,
  workbookData,
  setWorkBookData,
  selectedSheet,
  setSelectedSheet,
  sheetNamesArray,
  rows,
  setRows,
  headerRowData,
  companyid,
  cognito_identity_uuid,
  setAttachmentInfo,
  handleNext,
}) => {
  // Notify
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [loading, setLoading] = useState(false);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);

  useEffect(() => {
    if (rows.length > 0 && headerRowData.length > 0 && Object.keys(uploadFileData).length > 0) {
      setTableDataLoaded(true);
    } else {
      setTableDataLoaded(false);
    }
  }, [rows, headerRowData, uploadFileData]);

  // Upload File to S3
  const onFileInputChange = async (uploadFileData) => {
    try {
      if (!uploadFileData || uploadFileData.length === 0) {
        enqueueSnackbar('No file selected. Please choose a file to upload.', { variant: 'error' });
        return false;
      }

      let file = uploadFileData[0];

      if (!validateFileType('memberdetail', file)) {
        enqueueSnackbar('Invalid file type. Please upload a valid file.', { variant: 'error' });
        return false;
      }

      if (!validateFileSize(file)) {
        enqueueSnackbar('File size exceeds the limit. Please upload a file less than 5MB.', {
          variant: 'error',
        });
        return false;
      }

      const fileExists = await FileExists(companyid, cognito_identity_uuid, 'protected', file.name);

      if (fileExists) {
        const userConfirmed = window.confirm(
          'A file with the same name already exists. Do you want to overwrite it?'
        );
        if (!userConfirmed) {
          enqueueSnackbar('File upload cancelled.', { variant: 'info' });
          return false;
        }
      }

      const result = await UploadFileToS3(file, companyid, cognito_identity_uuid);

      if (result) {
        setAttachmentInfo([{ filelink: result }]);
        enqueueSnackbar('File uploaded successfully.', { variant: 'success' });
        return true;
      }
    } catch (error) {
      console.error('File upload error:', error);
      enqueueSnackbar('File upload to server failed! ' + error.message, { variant: 'error' });
      return false;
    }
  };

  // Handle Next Button and Upload File
  const handleSubmitNext = async () => {
    try {
      const success = await onFileInputChange(uploadFileData);
      if (success) {
        handleNext();
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };

  return (
    <Fragment>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
        UPLOAD FILE
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Please upload hours for the current pay period only.
      </Typography>
      {!Object.keys(uploadFileData).length > 0 && <DownloadFileSample />}
      {loading ? (
        <CustomLoadingScreen text="Loading Upload Table ..." />
      ) : (
        <Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {tableDataLoaded && sheetNamesArray.length > 0 ? (
                <Fragment>
                  <SelectSheetFile
                    workbookData={workbookData}
                    sheetNames={sheetNamesArray}
                    selectedSheet={selectedSheet}
                    setSelectedSheet={setSelectedSheet}
                  />
                  <Box sx={{ p: 1 }} />
                  <TablePreview rows={rows} setRows={setRows} columns={headerRowData} />
                </Fragment>
              ) : null}
            </Grid>
          </Grid>
        </Fragment>
      )}
      <Box sx={{ p: 1 }} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UploadDropZone
            setUploadFileData={setUploadFileData}
            setWorkBookData={setWorkBookData}
            setLoading={setLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              disabled={!Object.keys(uploadFileData).length > 0}
              sx={{ my: 1, ml: 1 }}
              type="submit"
              onClick={handleSubmitNext}
            >
              Next
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UploadFile;
