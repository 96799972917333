import { v4 } from 'uuid';
import {
  validSIN,
  findRepeatedCompanyNumber,
  findRepeatedPayPeriodDate,
} from '../../../../../common/HelperFunctions';

// Third Party Imports
import { parse, isAfter } from 'date-fns';

export const addErrorsAndRunHooks = (data, fields, rowHook, tableHook) => {
  const errors = {};
  const warnings = {};

  const addHookError = (rowIndex, fieldKey, error) => {
    errors[rowIndex] = {
      ...errors[rowIndex],
      [fieldKey]: error,
    };
  };

  const addHookWarning = (rowIndex, fieldKey, warning) => {
    warnings[rowIndex] = {
      ...warnings[rowIndex],
      [fieldKey]: warning,
    };
  };

  if (tableHook) {
    data = tableHook(data, addHookError, addHookWarning);
  }

  if (rowHook) {
    data = data.map((value, index) =>
      rowHook(
        value,
        (...props) => addHookError(index, ...props),
        (...props) => addHookWarning(index, ...props),
        data
      )
    );
  }

  fields.forEach((field) => {
    field.validations?.forEach((validation) => {
      switch (validation.rule) {
        // Error Validation Rules for File Parsing and Validation
        case 'unique': {
          const values = data.map((entry) => entry[field.key]);
          const taken = new Set();
          const duplicates = new Set();
          values.forEach((value) => {
            if (validation.allowEmpty && !value) {
              // If allowEmpty is set, we will not validate falsy fields such as undefined or empty string.
              return;
            }
            if (taken.has(value)) {
              duplicates.add(value);
            } else {
              taken.add(value);
            }
          });
          values.forEach((value, index) => {
            if (duplicates.has(value)) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message: validation.errorMessage || 'Field must be unique',
                },
              };
            }
          });
          break;
        }
        case 'required': {
          data.forEach((entry, index) => {
            if (
              entry[field.key] === null ||
              entry[field.key] === undefined ||
              entry[field.key] === ''
            ) {
              if (field.key === 'ltdhrs' || field.key === 'penhrs') {
                entry[field.key] = 0;
              } else {
                errors[index] = {
                  ...errors[index],
                  [field.key]: {
                    level: validation.level || 'error',
                    message: validation.errorMessage || 'Field is required',
                  },
                };
              }
            }
          });
          break;
        }
        case 'minLength': {
          data.forEach((entry, index) => {
            const value = entry[field.key]?.toString() ?? '';
            if (value.length < validation.value) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message:
                    validation.errorMessage ||
                    `Field must be at least ${validation.value} characters long`,
                },
              };
            }
          });
          break;
        }
        case 'validSIN': {
          data.forEach((entry, index) => {
            const value = entry[field.key]?.toString() ?? '';
            if (!validSIN(value)) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message: validation.errorMessage || 'Field must be a valid SIN',
                },
              };
            }
          });
          break;
        }
        case 'validCompanyNumber': {
          const isValid = (value) => value !== undefined && value !== null && value !== '';
          // Find the majority value
          const majorityValue = findRepeatedCompanyNumber(
            data.map((entry) => Number(entry[field.key]?.toString().replace(/\D/g, '') ?? '0'))
          );
          const isMajorityValue = (value) => {
            if (value === undefined || value === null || value === '') {
              return '0';
            }
            return Number(value.toString().replace(/\D/g, '')) === majorityValue;
          };

          data.forEach((entry, index) => {
            const value = entry[field.key]?.toString() ?? '';
            if (isValid(value) && !isMajorityValue(value)) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message:
                    validation.errorMessage ||
                    `Field must be equal Company Number (${majorityValue})`,
                },
              };
            }
          });
          break;
        }
        case 'validPayPeriodStartDate': {
          const isValid = (value) => value !== undefined && value !== null && value !== '';
          const payPeriodStartDateMajority = findRepeatedPayPeriodDate(
            data.map((entry) => entry[field.key]?.toString().replace(/\D/g, '') ?? '')
          );

          const isMajorityDateValue = (value) => {
            if (value === undefined || value === null || value === '') {
              return '';
            }
            return value.toString().replace(/\D/g, '') === payPeriodStartDateMajority;
          };

          data.forEach((entry, index) => {
            const value = entry[field.key]?.toString() ?? '';
            if (isValid(value) && !isMajorityDateValue(value)) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message:
                    validation.errorMessage ||
                    `Field must be equal Pay Period Start Date (${payPeriodStartDateMajority})`,
                },
              };
            }
          });
          break;
        }
        case 'validPayPeriodEndDate': {
          const isValid = (value) => value !== undefined && value !== null && value !== '';
          const payPeriodStartDateMajority = findRepeatedPayPeriodDate(
            data.map((entry) => entry[field.key]?.toString().replace(/\D/g, '') ?? '')
          );

          const isMajorityDateValue = (value) => {
            if (value === undefined || value === null || value === '') {
              return '';
            }
            return value.toString().replace(/\D/g, '') === payPeriodStartDateMajority;
          };

          data.forEach((entry, index) => {
            const value = entry[field.key]?.toString() ?? '';
            if (isValid(value) && !isMajorityDateValue(value)) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message:
                    validation.errorMessage ||
                    `Field must be equal Pay Period End Date (${payPeriodStartDateMajority})`,
                },
              };
            }
          });
          break;
        }
        case 'dateFormat': {
          data.forEach((entry, index) => {
            const value = entry[field.key]?.toString() ?? '';
            const date = new Date(value);
            if (isNaN(date.getTime())) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message: validation.errorMessage || 'Field must be a valid date',
                },
              };
            }
          });
          break;
        }
        case 'workStatusValidationRules': {
          const workStatusMapping = {
            S: 'S - Short Term Disability work status requires a valid',
            W: 'WCB - W work status requires a valid',
            L: 'Layoff - L work status requires a valid',
            I: 'ICBC - I work status requires a valid',
            E: 'LTD - E work status requires a valid',
            ESA: 'Employment Standards Act - ESA work status requires a valid',
          };

          data.forEach((entry, index) => {
            const workStatus = entry[field.key]?.toString() ?? '';
            const workStatusStartDate = entry['workstatusstartdate'];
            const workStatusEndDate = entry['workstatusenddate'];
            const warnings = entry['warnings'];
            const ltdStatusOnClaim =
              warnings && warnings.length > 0 && warnings.includes('On LTD Claim.') ? true : false;
            const isValid = (value) => value !== undefined && value !== null && value !== '';

            if (isValid(workStatus) && !ltdStatusOnClaim) {
              Object.keys(workStatusMapping).forEach((status) => {
                if (workStatus.trim() === status) {
                  if (!workStatusStartDate) {
                    errors[index] = {
                      ...errors[index],
                      workstatusstartdate: {
                        level: validation.level || 'error',
                        message:
                          validation.errorMessage ||
                          `${workStatusMapping[status]} start date - see instructions at top of page.`,
                      },
                    };
                  }
                  if (!workStatusEndDate) {
                    errors[index] = {
                      ...errors[index],
                      workstatusenddate: {
                        level: validation.level || 'error',
                        message:
                          validation.errorMessage ||
                          `${workStatusMapping[status]} end date - see instructions at top of page.`,
                      },
                    };
                  }
                }
              });
            }
          });
          break;
        }
        case 'workStatusValidationRules2': {
          const workStatusMapping = {
            A: 'A - Active work status requires a valid',
            D: 'Deceased - D work status requires a valid',
            M: 'Management Transfer - M work status requires a valid',
            P: 'Jobless - P work status requires a valid',
            R: 'Retired - R work status requires a valid',
            T: 'Terminated - T work status requires a valid',
          };
          data.forEach((entry, index) => {
            const workStatus = entry[field.key]?.toString() ?? '';
            const workStatusStartDate = entry['workstatusstartdate'];
            const warnings = entry['warnings'];
            const ltdStatusOnClaim =
              warnings && warnings.length > 0 && warnings.includes('On LTD Claim.') ? true : false;
            const isValid = (value) => value !== undefined && value !== null && value !== '';

            if (isValid(workStatus) && !ltdStatusOnClaim) {
              Object.keys(workStatusMapping).forEach((status) => {
                if (workStatus.trim() === status) {
                  if (!workStatusStartDate) {
                    errors[index] = {
                      ...errors[index],
                      workstatusstartdate: {
                        level: validation.level || 'error',
                        message:
                          validation.errorMessage || `${workStatusMapping[status]} start date`,
                      },
                    };
                  }
                }
              });
            }
          });
          break;
        }
        case 'workStatusValidationCodes': {
          data.forEach((entry, index) => {
            let value = entry[field.key]?.toString() ?? '';
            value = value.trim();
            // Workstatus Allowable Codes
            const requireWorkStatusCodes = [
              'A',
              'D',
              'E',
              'ESA',
              'I',
              'L',
              'M',
              'P',
              'R',
              'S',
              'T',
              'W',
            ];
            const isValid = (value) => value !== undefined && value !== null && value !== '';
            if (isValid(value) && !requireWorkStatusCodes.includes(value)) {
              const validationLevel = validation.level || 'error';
              const errorMessage =
                'Work Status Code is not valid. Please use one of the following codes: A, D, E, ESA, I, L, M, P, R, S, T, W from the work status dropdown list';
              errors[index] = {
                ...errors[index],
                workstatus: {
                  level: validationLevel,
                  message: errorMessage,
                },
              };
            }
          });
          break;
        }
        case 'validateEmployeeName': {
          data.forEach((entry, index) => {
            const employeeInputSurname = entry[field.key]
              ? entry[field.key].trim().toUpperCase()
              : undefined;
            const employeeDBSurname = entry['surname'] ? entry['surname'].trim() : undefined;
            const isValid = (value) => value !== undefined && value !== null && value !== '';
            if (isValid(employeeInputSurname) && isValid(employeeDBSurname)) {
              if (employeeInputSurname !== employeeDBSurname) {
                const validationLevel = validation.level || 'error';
                const errorMessage = 'Employee name does not match';
                errors[index] = {
                  ...errors[index],
                  [field.key]: {
                    level: validationLevel,
                    message: errorMessage,
                  },
                };
              }
            }
          });
          break;
        }
        case 'validPensionHours': {
          data.forEach((entry, index) => {
            let value = entry[field.key]?.toString() ?? '';
            if (isNaN(value)) {
              value = 0;
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message: validation.errorMessage || 'Pension Hours must be a number or decimal',
                },
              };
            }
            entry[field.key] = Number(value);
          });
          break;
        }
        case 'validLtdHours': {
          data.forEach((entry, index) => {
            let value = entry[field.key]?.toString() ?? '';
            if (isNaN(value)) {
              value = 0;
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message: validation.errorMessage || 'LTD Hours must be a number or decimal',
                },
              };
            }
            entry[field.key] = Number(value);
          });
          break;
        }
        case 'maxPensionHours': {
          data.forEach((entry, index) => {
            const value = entry[field.key]?.toString() ?? '';
            const billingStatementDays = 20;
            const totalDays = billingStatementDays * 14;
            if (value > totalDays) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message:
                    validation.errorMessage ||
                    `Pension Hours must not exceed ${totalDays} hours per pay period`,
                },
              };
            }
          });
          break;
        }
        case 'hasPensionHoursContribution': {
          data.forEach((entry, index) => {
            const penHoursValue = parseInt(entry[field.key]?.toString()) || 0;
            const penHours = parseInt(entry['haspenhrs']);
            if (penHoursValue > 0 && penHours !== 1) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message:
                    validation.errorMessage ||
                    'Employee does not contribute to Pension Plan. Pension Hours must be 0',
                },
              };
            }
          });
          break;
        }
        case 'over71PensionHoursContribution': {
          data.forEach((entry, index) => {
            const penHoursValue = parseInt(entry[field.key]?.toString()) || 0;
            const penHours = parseInt(entry['haspenhrs']);
            if (penHoursValue > 0 && penHours === 2) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message:
                    validation.errorMessage ||
                    'Employee is older than 71; pension hours worked are not contributable. Pension Hours must be 0',
                },
              };
            }
          });
          break;
        }
        case 'NonNegativePensionHours': {
          // Pension Hours must not be negative
          data.forEach((entry, index) => {
            const penHoursValue = parseInt(entry[field.key]?.toString()) || 0;
            if (penHoursValue < 0) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message: validation.errorMessage || 'Pension Hours must not be negative',
                },
              };
            }
          });
          break;
        }
        case 'NonNegativeLTDHours': {
          // Ltd Hours must not be negative
          data.forEach((entry, index) => {
            const ltdHoursValue = parseInt(entry[field.key]?.toString()) || 0;
            if (ltdHoursValue < 0) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message: validation.errorMessage || 'LTD Hours must not be negative',
                },
              };
            }
          });
          break;
        }
        case 'WorkStatusEndDateIsIntheFuture': {
          data.forEach((entry, index) => {
            const workStatusEndDate = entry[field.key];
            if (workStatusEndDate) {
              if (isAfter(parse(workStatusEndDate, 'yyyy-MM-dd', new Date()), new Date())) {
                errors[index] = {
                  ...errors[index],
                  [field.key]: {
                    level: validation.level || 'error',
                    message:
                      validation.errorMessage || 'Work Status End Date cannot be in the future',
                  },
                };
              }
            }
          });
          break;
        }
        case 'WorkStatusStartDateIsIntheFuture': {
          data.forEach((entry, index) => {
            const workStatusStartDate = entry[field.key];
            if (workStatusStartDate) {
              if (isAfter(parse(workStatusStartDate, 'yyyy-MM-dd', new Date()), new Date())) {
                errors[index] = {
                  ...errors[index],
                  [field.key]: {
                    level: validation.level || 'error',
                    message:
                      validation.errorMessage || 'Work Status Start Date cannot be in the future',
                  },
                };
              }
            }
          });
          break;
        }
        case 'WorkStatusEndDateIsBeforeStartDate': {
          data.forEach((entry, index) => {
            const workStatusEndDate = entry[field.key];
            const workStatusStartDate = entry['workstatusstartdate'];
            if (workStatusEndDate && workStatusStartDate) {
              if (
                parse(workStatusEndDate, 'yyyy-MM-dd', new Date()) <
                parse(workStatusStartDate, 'yyyy-MM-dd', new Date())
              ) {
                errors[index] = {
                  ...errors[index],
                  [field.key]: {
                    level: validation.level || 'error',
                    message:
                      validation.errorMessage ||
                      'Work Status End Date cannot be be before Work Status Start Date',
                  },
                };
              }
            }
          });
          break;
        }
        case 'isWorkStatusStartAfterPrior': {
          data.forEach((entry, index) => {
            const workStatusStartDate = entry[field.key];
            const priorWorkStatusEndDate = entry['curr_workstatusstartdate'];
            const warnings = entry['warnings'];
            const ltdStatusOnClaim =
              warnings && warnings.length > 0 && warnings.includes('On LTD Claim.') ? true : false;
            if (workStatusStartDate && priorWorkStatusEndDate && !ltdStatusOnClaim) {
              if (
                parse(workStatusStartDate, 'yyyy-MM-dd', new Date()) <
                parse(priorWorkStatusEndDate, 'yyyy-MM-dd', new Date())
              ) {
                errors[index] = {
                  ...errors[index],
                  [field.key]: {
                    level: validation.level || 'error',
                    message: `The start date for the current work status must be later than the previous work start date on record. The prior work status start date we have on file is ${priorWorkStatusEndDate}.`,
                  },
                };
              }
            }
          });
          break;
        }
        // Warnings for File Parsing and Validation
        case 'validateActiveEmployeeNoHours': {
          data.forEach((entry, index) => {
            const workStatus = entry['workstatus']?.toString() ?? '';
            const workStatusStartDate = entry['workstatusstartdate'];
            const penHours = entry['penhrs'];
            const hasPenHours = entry['haspenhrs'];
            const flags = entry['warnings'];

            // Check if value is valid
            const isValid = (value) => value !== undefined && value !== null && value !== '';

            const isOnClaim = (flags) => flags && flags.includes('On LTD Claim.');

            if (isValid(workStatusStartDate) && !isOnClaim(flags) && workStatus.trim() === 'A') {
              if (penHours === 0 && hasPenHours === '1') {
                const validationLevel = validation.level || 'warning';
                const warningMessage = 'Employee is Active but has no hours entered';
                warnings[index] = {
                  ...warnings[index],
                  penhrs: {
                    level: validationLevel,
                    message: warningMessage,
                  },
                };
              }
            }
          });
          break;
        }
        case 'validateWorkEndDateBeforePayPeriodStartDate': {
          data.forEach((entry, index) => {
            const workStatus = entry['workstatus']?.toString() ?? '';
            const workStatusEndDate = entry['workstatusenddate'] ?? '';
            const payPeriodStartDate = entry['PeriodStart'] ?? '';
            const flags = entry['warnings'];

            // Check if value is valid
            const isValid = (value) => value !== undefined && value !== null && value !== '';

            // Function to check if the employee is on claim
            const isOnClaim = (flags) => flags && flags.includes('On LTD Claim.');

            // Validate only if workStatusEndDate is valid and employee is not on claim
            if (isValid(workStatusEndDate) && !isOnClaim(flags) && workStatus.trim() !== 'A') {
              const endDate = parse(workStatusEndDate, 'yyyy-MM-dd', new Date());
              const startDate = parse(payPeriodStartDate, 'yyyy-MM-dd', new Date());

              // Check if work status end date is before pay period start date
              if (endDate < startDate) {
                const validationLevel = validation.level || 'warning';
                const warningMessage = 'Work Status End Date is before Pay Period Start Date';
                warnings[index] = {
                  ...warnings[index],
                  workstatusenddate: {
                    level: validationLevel,
                    message: warningMessage,
                  },
                };
              }
            }
          });
          break;
        }
        case 'hasLtdHoursContribution': {
          data.forEach((entry, index) => {
            const ltdHoursValue = parseInt(entry[field.key]?.toString()) || 0;
            // Skip error logic if ltdHoursValue is 0
            if (ltdHoursValue === 0) return;
            const ltdHours = parseInt(entry['hasltdhrs']);
            const employmentType = entry['employmenttype'];
            let editmsg = '';
            if (
              ltdHoursValue > 0 &&
              (ltdHours !== 1 || (employmentType.length !== 0 && employmentType !== 'REGULAR'))
            ) {
              switch (ltdHours) {
                case 0:
                  editmsg =
                    'Employee does not contribute to LTD Plan, you have to set the LTD hours to 0.';
                  break;
                case 2:
                  editmsg =
                    'Employee is older than 59.5; LTD hours worked are not contributable, you have to set the LTD hours to 0.';
                  break;
                default:
                  editmsg =
                    'Casual employees do not qualify for LTD coverage/benefits, you have to set the LTD hours to 0.';
                  break;
              }
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message: validation.errorMessage || editmsg,
                },
              };
            }
          });
          break;
        }
        case 'validateLTDHoursLessThanOrEqualToPensionHours': {
          data.forEach((entry, index) => {
            const ltdHoursValue = parseInt(entry[field.key]?.toString()) || 0;
            const penHoursValue = parseInt(entry['penhrs']) || 0;
            const penHours = parseInt(entry['haspenhrs']);
            if (ltdHoursValue > penHoursValue && penHours === 1) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message:
                    validation.errorMessage ||
                    'LTD Hours must be less than or equal to Pension Hours',
                },
              };
            }
          });
          break;
        }
        case 'validateNonCasualMemberForLtdPlan': {
          data.forEach((entry, index) => {
            const employmentType = entry['employmenttype'];
            const flags = entry['warnings'];
            const isOnClaim = (value) =>
              value !== undefined &&
              value !== null &&
              value !== '0' &&
              value.length > 0 &&
              value.includes('On LTD Claim.');

            if (
              isOnClaim(flags) &&
              ((employmentType.length !== 0 && employmentType.trim() === 'CASUAL') ||
                employmentType.trim() === 'PART TIME' ||
                employmentType.trim() === 'SUMMR STDT')
            ) {
              errors[index] = {
                ...errors[index],
                [field.key]: {
                  level: validation.level || 'error',
                  message:
                    validation.errorMessage ||
                    'Casual employees do not qualify for LTD coverage/benefits, change employment type REGULAR.',
                },
              };
            }
          });
          break;
        }
        default:
          break;
      }
    });
  });
  return data.map((value, index) => {
    if (!('__index' in value)) {
      value.__index = v4();
    }
    const newValue = value;
    if (errors[index]) {
      return { ...newValue, __errors: errors[index] };
    }
    if (!errors[index] && value?.__errors) {
      return { ...newValue, __errors: null };
    }
    if (warnings[index]) {
      return { ...newValue, __warnings: warnings[index] };
    }
    if (!warnings[index] && value?.__warnings) {
      return { ...newValue, __warnings: null };
    }
    return newValue;
  });
};
