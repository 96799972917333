import React, { Fragment, useState, useEffect } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet, handlePost, handlePut } from '../utils/amplifyInstance';

// AWS Amplify - Storage
import { list, downloadData, uploadData } from 'aws-amplify/storage';

// Material-UI
import { Autocomplete, TextField, CircularProgress, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

// Third Party
import {
  VirtualTable,
  TableEditRow,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';

// Date Functions
import { format, isAfter, isValid, parseISO, parse } from 'date-fns';

// Custom Styles - Table Inline Cell Editing
export const StyledEditCell = styled(TableInlineCellEditing.Cell)(({ theme }) => ({
  paddingTop: '0px !important',
  paddingBottom: '0px !important',
  '&:first-of-type': {
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
}));

// Custom Classes - Datepicker Input
const classes = {
  datepickerInput: 'datepicker-input',
};

// Custom Styles - TextField
export const StyledTextField = styled(TextField)(({ theme }) => ({
  [`&.datepicker-input`]: {
    '& input::-webkit-calendar-picker-indicator': {
      marginLeft: '0px',
      opacity: 0.42,
    },
    '& input::-webkit-datetime-edit-fields-wrapper': {
      opacity: 0.42,
    },
  },
}));

// File Exists - Check if file exists in S3
export async function FileExists(companyid, cognito_identity_uuid, level, filename) {
  var options = {
    level: level,
    pageSize: 1000,
    listAll: true,
    path: `protected/${cognito_identity_uuid}/${companyid}/employer/`,
  };

  var fileList = await list(options);
  //remove folders from list
  fileList = fileList.items.filter((file) => {
    return file.path.split('/').pop() !== '';
  });

  var pathList = fileList.map((i) => i.path);

  return pathList.includes(companyid + '/employer/' + filename);
}

// Upload File
export async function UploadFile(file, companyid, cognito_identity_uuid) {
  var period = file.name.lastIndexOf('.');
  var pluginName = file.name.substring(0, period);
  var fileExtension = file.name.substring(period + 1);

  const filename =
    companyid +
    '/employer/' +
    pluginName +
    '_' +
    format(new Date(), 'yyyyMMddHHmmss') +
    '.' +
    fileExtension;

  const path = `protected/${cognito_identity_uuid}/${filename}`;
  const data = file;
  const options = {
    contentType: file.type,
    metadata: {
      level: 'protected',
    },
  };

  const result = await uploadData({
    path,
    data,
    ...options,
  }).result;

  const resolvedResult = await result;

  return resolvedResult.path;
}

// Upload Multiple Attachments
export async function UploadFileMultipleAttachments(file, companyid, cognito_identity_uuid) {
  let resultFiles = [];
  for (let i = 0; i < file.length; i++) {
    var period = file[i].name.lastIndexOf('.');
    var pluginName = file[i].name.substring(0, period);
    var fileExtension = file[i].name.substring(period + 1);

    const filename =
      companyid +
      '/employer/' +
      pluginName +
      '_' +
      format(new Date(), 'yyyyMMddHHmmss') +
      '.' +
      fileExtension;

    try {
      const path = `protected/${cognito_identity_uuid}/${filename}`;
      const data = file[i];
      const options = {
        contentType: file[i].type,
        metadata: {
          level: 'protected',
        },
      };

      const result = await uploadData({
        path,
        data,
        ...options,
      });
      const resolvedResult = await result.result;
      resultFiles.push(resolvedResult.path);
    } catch (e) {
      console.log('Error in Upload File Multiple Attachments Function', e);
    }
  }
  return resultFiles;
}

// Download Blod
export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

// Generate a download URL
export async function DownloadFile(filelink, identityid) {
  var options = {
    level: '',
    validateObjectExistence: true,
    expiresIn: 3600,
    useAccelerateEndpoint: true,
  };

  if (identityid) {
    options.level = 'protected';
    options.identityId = identityid;
    options.path = filelink.startsWith('protected/')
      ? filelink
      : `protected/${identityid}/${filelink}`;
  } else {
    options.level = 'public';
    options.path = `public/${filelink}`;
  }

  try {
    const downloadResult = await downloadData(options).result;
    const blob = await downloadResult.body.blob();
    downloadBlob(blob, filelink.split('/').pop());
  } catch (e) {
    if (filelink) {
      alert(filelink.split('/').pop() + ' not found. Please contact Administrator');
    } else {
      alert('File link is invalid. Please contact Administrator');
    }
  }
}

// Post Message to Employer
export async function PostMessage(accountid, message, authToken) {
  let init = {
    message: message,
  };

  const response = await handlePost({
    apiName: 'EmployerApiGw',
    path: '/employer/' + accountid + '/message',
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: init,
  });

  const { body } = await response;
  const json = await body.json();

  if (json.result === -1) {
    throw json.message;
  }
}

export async function UpdateStatus(accountid, companyid, statementid, authToken) {
  var init = {
    hoursuploaded: 'yes',
  };

  const response = await handlePut({
    apiName: 'EmployerApiGw',
    path: '/employer/' + accountid + '/' + companyid + '/jsondoc/hoursuploaded/' + statementid,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: init,
  });

  const { body } = await response;
  const json = await body.json();

  if (json.result === -1) {
    throw json.message;
  }
}

// Has Profile - Excel File Parsing
export const HasProfile = async (accountid, companyid, authToken) => {
  var hasprofile = {};
  try {
    const response = await handleGet({
      apiName: 'EmployerApiGw',
      path: '/employer/' + accountid + '/' + companyid + '/profile/exists?profiletype=COLUMNMAP',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const { body } = await response;
    const json = await body.json();

    hasprofile = json.items[0];
  } catch (e) {
    console.log(e);
  }

  return hasprofile;
};

export const toDecimal = (value) =>
  new Intl.NumberFormat('en-US', {
    style: 'decimal',
  }).format(value);

export const numericBoldFormat = ({ value }) => {
  return <b style={{ color: '#008522' }}>{toDecimal(value)}</b>;
};

export const toCurrency = (value) => {
  return value.length === 0
    ? ''
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value);
};

export const currencyBoldFormat = ({ value }) => {
  return <b style={{ color: '#008522' }}>{toCurrency(value)}</b>;
};

export const currencyFormat = ({ value }) => {
  return toCurrency(value);
};

export const SINMaskingFormatter = ({ value }) => {
  if (value) {
    return value.length > 0 ? '***-***-' + value.substr(value.length - 3) : value;
  } else {
    return '';
  }
};

export const validSIN = (sin) => {
  var check, even, tot;

  if (sin.substr(0, 3).toUpperCase() === 'UNK') {
    return true;
  }

  if (sin === '000000000') {
    return false;
  }

  if (typeof sin !== 'number' && isNaN(sin)) {
    return false;
  } else {
    sin = sin.toString();
  }

  // convert to an array & pop off the check digit
  if (sin.length === 9) {
    sin = sin.split('');
    check = +sin.pop();

    // take the digits at the even indices
    // multiply them by two
    // and split them into individual digits
    even = sin
      .filter(function (_, i) {
        return i % 2;
      })
      .map(function (n) {
        return n * 2;
      })
      .join('')
      .split('');

    // take the digits at the odd indices
    // concatenate them with the transformed numbers above
    // it's currently an array of strings; we want numbers
    // and take the sum
    tot = sin
      .filter(function (_, i) {
        return !(i % 2);
      })
      .concat(even)
      .map(function (n) {
        return +n;
      })
      .reduce(function (acc, cur) {
        return acc + cur;
      });

    // compare the result against the check digit
    return check === (10 - (tot % 10)) % 10;
  } else {
    return false;
  }
};

export const NoDataCell = () => {
  return <VirtualTable.Cell>N/A</VirtualTable.Cell>;
};

export const FocusableCell = ({ onClick, ...props }) => {
  return <VirtualTable.Cell {...props} tabIndex={0} onFocus={onClick} />;
};

export const LookupEditCell = ({
  accountid,
  authToken,
  ltdCompany,
  readOnlyMode,
  value,
  onValueChange,
  column,
  row,
  ...props
}) => {
  const LOV = column.name;
  // const filterValue = column.title;
  const filterValue = column.name === "employmenttype" ? "Work Type" : column.title;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      try {
        const cachedHits = sessionStorage.getItem(LOV);

        if (cachedHits) {
          setData(JSON.parse(cachedHits).filter((i) => i.group === filterValue));
        } else {
          const response = await handleGet({
            apiName: 'EmployerApiGw',
            path: '/employer/' + accountid + '/' + LOV,
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });

          const { body } = await response;
          const json = await body.json();

          if (cancel) {
            return;
          }

          sessionStorage.setItem(LOV, JSON.stringify(json.items));
          setData(json.items.filter((i) => i.group === filterValue));
        }
      } catch (e) {
        setData(null);
      }

      setIsLoading(false);
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, [LOV, filterValue, accountid, authToken]);

  return (
    <StyledEditCell {...props}>
      <Autocomplete
        value={value}
        fullWidth
        disableClearable
        options={data.map((option) => option.label)}
        isOptionEqualToValue={(option, value) => true}
        loading={isLoading}
        getOptionDisabled={(option) =>
          readOnlyMode ||
          (option === 'E - LTD' && ltdCompany === 'Y') ||
          option ===
            data
              .filter((i) => i.value === value)
              .map((i) => i.label)
              .toString()
        }
        onChange={(event, newValue) => {
          if (LOV === 'workstatus') {
            row.enddate = data
              .filter((i) => i.label === newValue)
              .map((i) => i.enddate)
              .toString();
          }
          onValueChange(
            data
              .filter((i) => i.label === newValue)
              .map((i) => i.value)
              .toString()
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="none"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={10} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </StyledEditCell>
  );
};

export const StaticEditCell = () => {
  const flatProps = {
    options: [{ value: 'E', label: 'E - LTD', enddate: 'O', group: 'Status' }],
    getOptionLabel: (option) => option.label,
  };

  return (
    <>
      <StyledEditCell>
        <Tooltip
          title="Member on IWA LTD Claim"
          placement="bottom-start"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#2196f3',
                '& .MuiTooltip-arrow': {
                  color: '#2196f3',
                },
              },
            },
          }}
        >
          <Autocomplete
            {...flatProps}
            id="OnLTDClaimReadOnly"
            readOnly
            disabled={true}
            defaultValue={flatProps.options[0]}
            isOptionEqualToValue={(option, value) => true}
            renderInput={(params) => (
              <TextField {...params} margin="none" fullWidth variant="standard" />
            )}
          />
        </Tooltip>
      </StyledEditCell>
    </>
  );
};

export const LookupValues = (props) => {
  const LOV = props.name;
  const authToken = props.authToken;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      try {
        const cachedHits = sessionStorage.getItem(LOV);

        if (cachedHits) {
          setData(JSON.parse(cachedHits));
        } else {
          const response = await handleGet({
            apiName: 'EmployerApiGw',
            path: '/employer/' + props.accountid + '/' + LOV,
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });

          const { body } = await response;
          const json = await body.json();

          if (cancel) {
            return;
          }
          sessionStorage.setItem(LOV, JSON.stringify(json.items));
          setData(json.items);
        }
      } catch (e) {
        setData(null);
      }

      setIsLoading(false);
    };

    fetchData();

    return () => {
      cancel = true;
    };
  }, [LOV, authToken, props.accountid]);

  return (
    <Autocomplete
      value={props.value}
      disableClearable={!props.clearable}
      isOptionEqualToValue={(option, value) => true}
      options={data.map((option) => option.label)}
      getOptionDisabled={(option) => props.readOnlyMode || option === props.value}
      loading={isLoading}
      onChange={(event, newValue) => {
        props.onValueChange(
          LOV,
          data
            .filter((i) => i.label === newValue)
            .map((i) => i.value)
            .toString()
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          placeholder={props.placeholder}
          margin="none"
          fullWidth
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? <CircularProgress color="inherit" size={10} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export const LookupFilterCell = (props) => {
  return (
    <LookupValues
      accountid={props.accountid}
      clearable={true}
      name={props.name}
      placeholder="Filter..."
      value={props.filter ? props.filter.value : ''}
      readOnlyMode={props.readOnlyMode}
      onValueChange={(name, value) => props.onFilter(value ? { value: value } : null)}
      authToken={props.authToken}
      {...props}
    />
  );
};

// Is Valid Number - Regex
export const isValidNumber = (newValue) => {
  const regexp = new RegExp('^-?[0-9]{0,6}(\\.[0-9]{0,2})?$');

  return regexp.test(newValue);
};

export const isCompleteDate = (newValue) => {
  const regexp1 = new RegExp(
    '^(([0]?)([1-9])|([12])(\\d)|([3])([01]))(-)(([Jj])([Aa])([Nn])|([Mm])([Aa])([Rr])|([Mm])([Aa])([Yy])|([Jj])([Uu])([Ll])|([Aa])([Uu])([Gg])|([Oo])([Cc])([Tt])|([Dd])([Ee])([Cc]))(-)((([1])([9])|([2])([0]))(\\d)(\\d))$'
  );
  const regexp2 = new RegExp(
    '^(([0]?)([1-9])|([12])(\\d)|([3])([0]))(-)(([Aa])([Pp])([Rr])|([Jj])([Uu])([Nn])|([Ss])([Ee])([Pp])|([Nn])([Oo])([Vv]))(-)((([1])([9])|([2])([0]))(\\d)(\\d))$'
  );
  const regexp3 = new RegExp(
    '^(([0]?)([1-9])|([1])(\\d)|([2])([0-8]))(-)(([Ff])([Ee])([Bb]))(-)((([1])([9])|([2])([0]))(\\d)(\\d))$'
  );
  const regexp4 = new RegExp(
    '^(([2])([9]))(-)(([Ff])([Ee])([Bb]))(-)((([1])([9])|([2])([0]))([02468])([048])|([13579])([26]))$'
  );

  return (
    newValue.length === 0 ||
    regexp1.test(newValue) ||
    regexp2.test(newValue) ||
    regexp3.test(newValue) ||
    regexp4.test(newValue)
  );
};

export const isValidDate = (newValue) => {
  const regexp1 = new RegExp(
    '^((?:[0]?|$)(?:[1-9]|$)|(?:[12]|$)(?:\\d|$)|(?:[3]|$)(?:[01]|$))(?:-|$)((?:[Jj]|$)(?:[Aa]|$)(?:[Nn]|$)|(?:[Mm]|$)(?:[Aa]|$)(?:[Rr]|$)|(?:[Mm]|$)(?:[Aa]|$)(?:[Yy]|$)|(?:[Jj]|$)(?:[Uu]|$)(?:[Ll]|$)|(?:[Aa]|$)(?:[Uu]|$)(?:[Gg]|$)|(?:[Oo]|$)(?:[Cc]|$)(?:[Tt]|$)|(?:[Dd]|$)(?:[Ee]|$)(?:[Cc]|$))(?:-|$)(((?:[1]|$)(?:[9]|$)|(?:[2]|$)(?:[0]|$))(?:\\d|$)(?:\\d|$))$'
  );
  const regexp2 = new RegExp(
    '^((?:[0]?|$)(?:[1-9]|$)|(?:[12]|$)(?:\\d|$)|(?:[3]|$)(?:[0]|$))(?:-|$)((?:[Aa]|$)(?:[Pp]|$)(?:[Rr]|$)|(?:[Jj]|$)(?:[Uu]|$)(?:[Nn]|$)|(?:[Ss]|$)(?:[Ee]|$)(?:[Pp]|$)|(?:[Nn]|$)(?:[Oo]|$)(?:[Vv]|$))(?:-|$)(((?:[1]|$)(?:[9]|$)|(?:[2]|$)(?:[0]|$))(?:\\d|$)(?:\\d|$))$'
  );
  const regexp3 = new RegExp(
    '^((?:[0]?|$)(?:[1-9]|$)|(?:[1]|$)(?:\\d|$)|(?:[2]|$)(?:[0-8]|$))(?:-|$)((?:[Ff]|$)(?:[Ee]|$)(?:[Bb]|$))(?:-|$)(((?:[1]|$)(?:[9]|$)|(?:[2]|$)(?:[0]|$))(?:\\d|$)(?:\\d|$))$'
  );
  const regexp4 = new RegExp(
    '^((?:[2]|$)(?:[9]|$))(?:-|$)((?:[Ff]|$)(?:[Ee]|$)(?:[Bb]|$))(?:-|$)(((?:[1]|$)(?:[9]|$)|(?:[2]|$)(?:[0]|$))(?:[02468]|$)(?:[048]|$)|(?:[13579]|$)(?:[26]|$))$'
  );
  const regexp5 = new RegExp(
    '^((?:[D]|$)(?:[D]|$))(?:-|$)((?:[M]|$)(?:[M]|$)(?:[M]|$))(?:-|$)((?:[Y]|$)(?:[Y]|$)(?:[Y]|$)(?:[Y]|$))$'
  );

  //  return (regexp1.test(newValue) || regexp2.test(newValue) || regexp3.test(newValue) || regexp4.test(newValue) || !(newValue.length > 0 && newValue.length < 11));
  return (
    regexp1.test(newValue) ||
    regexp2.test(newValue) ||
    regexp3.test(newValue) ||
    regexp4.test(newValue) ||
    regexp5.test(newValue)
  );
};

export const NativeDateEditCell = ({ value, onValueChange, ...props }) => {
  const handleChange = (event) => {
    onValueChange(event.target.value);
  };

  return (
    <StyledTextField
      className={classes.datepickerInput}
      type="date"
      value={value}
      onChange={handleChange}
      inputProps={{
        min: '1971-01-01',
        max: `${format(new Date(), 'yyyy-MM-dd')}`,
      }}
      onKeyDown={props.onKeyDown}
      autoFocus={props.autoFocus}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    />
  );
};

export const NativeDateFilterCell = (props) => {
  const handleChange = (event) => {
    props.onFilter(event.target.value);
  };

  return (
    <StyledTextField
      className={classes.datepickerInput}
      type="date"
      value={props.filter ? props.filter.value : ''}
      onChange={handleChange}
      inputProps={{
        min: '1971-01-01',
        max: `${format(new Date(), 'yyyy-MM-dd')}`,
      }}
    />
  );
};

export const isUndefined = (value) => {
  return typeof value === 'undefined';
};

export const StringEditCell = (props) => {
  const handleChange = (newValue) => {
    props.onValueChange(newValue.toUpperCase());
  };

  return <TableEditRow.Cell {...props} onValueChange={handleChange} />;
};

export const validateFileSize = (file) => {
  if (file.size <= 5242880) {
    return true;
  }

  return false;
};

export const validateFileType = (page, file) => {
  var idxDot = file.name.lastIndexOf('.') + 1;
  var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();

  if (
    (page === 'messagecreate' &&
      (extFile === 'xls' ||
        extFile === 'xlsx' ||
        extFile === 'docx' ||
        extFile === 'doc' ||
        extFile === 'jpg' ||
        extFile === 'tif' ||
        extFile === 'pdf' ||
        extFile === 'txt' ||
        extFile === 'csv' ||
        extFile === 'zip')) ||
    (page === 'memberdetail' &&
      (extFile === 'xls' || extFile === 'xlsx' || extFile === 'txt' || extFile === 'csv'))
  ) {
    return true;
  }

  return false;
};

export const pad = (number) => {
  if (number < 10) {
    return '0' + number;
  }
  return number;
};

export const isEquivalent = (a, b) => {
  var equivalent = true;
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    equivalent = false;
  }

  var i = 0;
  while (equivalent && i < aProps.length) {
    var propName = aProps[i];
    if (a[propName] !== b[propName]) {
      equivalent = false;
    }
    i++;
  }

  return equivalent;
};

/**
 * Converts a given date-time string to a more readable time format.
 * If the value is empty, returns "Not Applicable".
 * Otherwise, returns the formatted date in the format "YYYY-MM-DD HH:mm:ss".
 */
export function convertToReadableTime(dateTime) {
  if (dateTime === '') {
    return 'Not Applicable';
  }

  const parsedDate = parseISO(dateTime);

  if (!isValid(parsedDate)) {
    return 'Invalid Date';
  }

  const formattedTime = format(parsedDate, 'yyyy-MM-dd HH:mm:ss');

  return formattedTime;
}

// Greater Date comparison with date-fns
export function greaterDate(d1, d2) {
  const date1 = parse(d1, 'yyyy-MM-dd', new Date());
  const date2 = parse(d2, 'yyyy-MM-dd', new Date());
  return isAfter(date1, date2) ? d1 : d2;
}

// Function that calculates the number of days between two dates (Billing Statement Start - End).
export function calculateDays(startDate, endDate) {
  const date1 = parse(startDate, 'yyyy-MM-dd', new Date());
  const date2 = parse(endDate, 'yyyy-MM-dd', new Date());

  // Calculate the difference in milliseconds
  const diffInMs = Math.abs(date2 - date1);

  // Convert back to days and return
  return Math.round(diffInMs / (1000 * 60 * 60 * 24)) + 1;
}

// CellNumber Check if it is null - Don't add +1
export const validateCellNumber = (value) => {
  let emptyCellNumber = '';
  if (value === '') {
    return emptyCellNumber;
  } else {
    let NewCellNumber = value.replace(/[\n\r\s\t()-.]/g, '');
    return '+1' + NewCellNumber;
  }
};

export function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}.${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  if (phoneNumberLength <= 10) {
    return `${phoneNumber.slice(0, 3)}.${phoneNumber.slice(3, 6)}.${phoneNumber.slice(6, 10)}`;
  }

  // if phoneNumberLength is greater than 10, return the first 15 digits or spaces
  return phoneNumber.slice(0, 15);
}

// Helper function to find the majority value - File Parsing Company Number
export function findRepeatedCompanyNumber(values) {
  const valueCounts = values.reduce((counts, value) => {
    counts[value] = (counts[value] || 0) + 1;
    return counts;
  }, {});

  let majorityValue = null;
  let maxCount = 0;
  for (const [value, count] of Object.entries(valueCounts)) {
    if (count > maxCount) {
      maxCount = count;
      majorityValue = Number(value);
    }
  }

  return majorityValue;
}

// Helper function to find the majority repeated value - File Parsing Pay Period Start Date
export function findRepeatedPayPeriodDate(values) {
  const valueCounts = values.reduce((counts, value) => {
    counts[value] = (counts[value] || 0) + 1;
    return counts;
  }, {});

  let majorityValue = null;
  let maxCount = 0;
  for (const [value, count] of Object.entries(valueCounts)) {
    if (count > maxCount) {
      maxCount = count;
      majorityValue = value;
    }
  }

  return majorityValue;
}

// Helper function to convert input value to a number
export function checkInput(inputValue) {
  if (inputValue == null || Number.isNaN(inputValue)) return null;
  return Number(inputValue);
}
