import React, { useState, Fragment } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet, handlePost } from '../../utils/amplifyInstance';

// Auth Session
import { useSession } from '../../context/AuthSession';

// Material-UI
import { Alert, Grid, Typography, TextField, InputAdornment, Button } from '@mui/material';

// Third Party
import { useSnackbar } from 'notistack';

// Helper Functions
import useMakePADForm from '../../common/useMakePADForm';
import { validateMakePADInfo } from '../../common/ValidationRules';
import { toCurrency, LookupValues, isUndefined } from '../../common/HelperFunctions';

// Project Imports
import CustomLoadingScreen from '../../ui-components/CustomLoadingScreen';
import LoadingStateButton from '../../ui-components/LoadingStateButton';

// Icons
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// ===========================|| MAKE PAD ||=========================== //
function MakePAD(props) {
  const { authToken } = useSession();
  const {
    accountid,
    companyid,
    padagreementid,
    amountreadonly,
    setStatusChanged,
    setOpenPADDialog,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [cancelEdit, setCancelEdit] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [isPADVerified, setIsPADVerified] = useState(false);

  const updateData = async () => {
    try {
      const response = await handlePost({
        apiName: 'EmployerApiGw',
        path: `/employer/${accountid}/${companyid}/payment/${padagreementid}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: [data],
      });

      const { body } = await response;
      const json = await body.json();

      if (json.result === 1) {
        if (setStatusChanged) {
          setStatusChanged(true);
        }
        enqueueSnackbar('Pre Authorized Debit created successfully!', { variant: 'success' });
        setOpenPADDialog(false);
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const verifyPADAuthorizationValues = async (amount) => {
    if (!amount || !accountid || !companyid || !authToken) {
      return;
    }

    try {
      const response = await handleGet({
        apiName: 'EmployerApiGw',
        path: `/employer/${accountid}/${companyid}/payment/exists?amount=${amount}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const { body } = await response;
      const json = await body.json();

      if (json.items.length > 0) {
        setIsPADVerified(true);
      } else {
        setIsPADVerified(false);
      }
    } catch (e) {
      console.log(e);
      setIsPADVerified(false);
    }
  };

  const submitData = async () => {
    if (!cancelEdit && confirmSubmit) {
      await updateData();
      if (setStatusChanged) {
        setStatusChanged(true);
      }
      setOpenPADDialog(false);
    } else {
      verifyPADAuthorizationValues(data.onetime_startamount);
      setShowConfirmation(true);
    }
  };

  const handleConfirmSubmit = async () => {
    try {
      setConfirmSubmit(true);
      await updateData();
    } catch (error) {
      console.log(error);
    }
  };

  const hookProps = { ...props, authToken };

  const { isLoading, data, errors, handleChange, handleLOVChange, handleSubmit } = useMakePADForm(
    submitData,
    validateMakePADInfo,
    hookProps
  );
  
  return (
    <Fragment>
      {isLoading ? (
        <CustomLoadingScreen text="Loading Make a Payment" />
      ) : (
        <>
          {!showConfirmation && (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={1}>
                <Grid item xs={12} zeroMinWidth>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                    }}
                  >
                    Pay by Direct Debit
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Make a payment by debit from your bank account.
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={12} sm={3} zeroMinWidth>
                  <Typography variant="body2" noWrap sx={{ paddingTop: '5px' }}>
                    Payment reason:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} zeroMinWidth>
                  <LookupValues
                    accountid={accountid}
                    name="payee"
                    label=""
                    value={data.payee}
                    readOnlyMode={amountreadonly}
                    authToken={authToken}
                    onValueChange={handleLOVChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3} zeroMinWidth>
                  <Typography variant="body2" noWrap sx={{ paddingTop: '5px' }}>
                    Description:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} zeroMinWidth>
                  <TextField
                    id="note"
                    name="note"
                    fullWidth
                    value={data.note}
                    onChange={handleChange}
                    InputProps={{
                      readOnly: amountreadonly,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} zeroMinWidth>
                  <Typography variant="body2" noWrap sx={{ paddingTop: '11px' }}>
                    Payment options:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9} zeroMinWidth>
                  <Grid container item direction="row" zeroMinWidth sx={{ marginTop: '6px' }}>
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{
                        paddingRight: '5px',
                        paddingTop: '5px',
                      }}
                    >
                      Debit amount:
                    </Typography>
                    <TextField
                      required
                      id="onetime_startamount"
                      name="onetime_startamount"
                      value={data.onetime_startamount}
                      sx={{ width: 115 }}
                      InputProps={{
                        readOnly: amountreadonly,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      inputProps={{ style: { marginRight: '4px', textAlign: 'right' } }}
                      onChange={handleChange}
                      error={!isUndefined(errors.onetime_startamount)}
                      helperText={errors.onetime_startamount}
                    />
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{
                        paddingLeft: '10px',
                        paddingRight: '5px',
                        paddingTop: '5px',
                      }}
                    >
                      Payment date:
                    </Typography>
                    <TextField
                      required
                      id="onetime_startdate"
                      name="onetime_startdate"
                      value={data.onetime_startdate}
                      disabled
                      sx={{ width: 115 }}
                      inputProps={{ style: { marginRight: '4px', textAlign: 'right' } }}
                      FormHelperTextProps={{ style: { textAlign: 'right' } }}
                      onChange={handleChange}
                      error={!isUndefined(errors.onetime_startdate)}
                      helperText={
                        errors.onetime_startdate ? errors.onetime_startdate : 'DD-MMM-YYYY'
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      paddingBottom: '5px',
                      paddingTop: '5px',
                    }}
                  />
                </Grid>
                <Grid container item sm={6} justifyContent="flex-start">
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon size="small" />}
                    color="secondary"
                    onClick={(event) => {
                      setOpenPADDialog(false);
                      setCancelEdit(true);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid container item sm={6} justifyContent="flex-end">
                  <Button
                    variant="contained"
                    endIcon={<DoneIcon size="small" />}
                    type="submit"
                    color="primary"
                  >
                    Make Payment
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
      {showConfirmation && (
        <Grid container spacing={1}>
          <Grid item xs={12} zeroMinWidth>
            {/* Payment Confirmation Start */}
            <Typography
              gutterBottom
              variant="subtitle1"
              sx={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
              }}
            >
              Payment Confirmation
            </Typography>
            {isPADVerified && (
              <Alert severity="warning" sx={{ marginBottom: '10px' }}>
                Your current payment amount matches a previous payment. Please verify the amount you’re paying today and try again.
                If you wish to proceed, click the Confirm button.
              </Alert>
            )}
            <Typography variant="body2" gutterBottom>
              Please confirm that you are authorizing a payment of{' '}
              {toCurrency(data.onetime_startamount)} CAD to be debited from your account on the next
              5 business days. Once you have authorized this payment, it cannot be cancelled.
            </Typography>
          </Grid>
          {/* Payment Confirmation End */}
          <Grid container spacing={1} sx={{ p: 1 }}>
            <Grid container item sm={6} justifyContent="flex-start">
              <Button
                variant="outlined"
                startIcon={<ArrowBackIosIcon size="small" />}
                color="secondary"
                onClick={() => setShowConfirmation(false)}
              >
                Back
              </Button>
            </Grid>
            <Grid container item sm={6} justifyContent="flex-end">
              <LoadingStateButton onClick={handleConfirmSubmit} text="Confirm" />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}

export default MakePAD;
