import React, { useState } from 'react';

// React Dom
import { flushSync } from 'react-dom';

// Material-UI
import { Button } from '@mui/material';

// Icons
import DoneIcon from '@mui/icons-material/Done';

export function LoadingStateButton({ onClick, text }) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={isLoading}
      onClick={async () => {
        if (isLoading) {
          return;
        }
        flushSync(() => setIsLoading(true));
        try {
          await onClick();
        } finally {
          setIsLoading(false);
        }
      }}
      endIcon={<DoneIcon size="small" />}
    >
      {isLoading ? 'Loading' : text}
    </Button>
  );
}

export default LoadingStateButton;
