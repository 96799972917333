import React, { useState, useEffect, Fragment } from 'react';

// Material UI
import { Card, CardContent, Typography, Stack, SvgIcon, Button } from '@mui/material';

// Icons
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';

const ChunkErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (error) => {
      if (
        error.name === 'ChunkLoadError' ||
        error.message.includes('Failed to fetch dynamically imported module')
      ) {
        setHasError(true);
      }
    };

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  if (hasError) {
    return (
      <Fragment>
        <Card>
          <CardContent>
            <Stack alignItems="center" direction="row" flexWrap="wrap" spacing={1}>
              <Stack direction="row" spacing={1}>
                <ErrorOutlineIcon sx={{ color: '#d14124' }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#d14124' }}>
                  Failed to load page. Please reload the page and try again.
                </Typography>
              </Stack>
            </Stack>
            <Button
              sx={{ mt: 2 }}
              onClick={() => window.location.reload()}
              variant="contained"
              endIcon={
                <SvgIcon>
                  <RefreshIcon />
                </SvgIcon>
              }
            >
              Reload Page
            </Button>
          </CardContent>
        </Card>
      </Fragment>
    );
  }

  return children;
};

export default ChunkErrorBoundary;
