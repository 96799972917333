export const fields = [
  {
    label: "Company Number",
    key: "CompanyNumber",
    alternateMatches: ["Company Number", "Company", "Company & Division", "COMPANY & DIVISION"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Company Number is required.",
      },
      {
        rule: "validCompanyNumber",
        errorMessage: "Company Number is invalid.",
      }
    ],
  },
  {
    label: "Statement Id",
    key: "StatementId",
    alternateMatches: ["Statement Id", "Statement", "STATEMENT ID"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Period Start",
    key: "PeriodStart",
    alternateMatches: ["Period Start", "Start", "CONTRIBUTION PERIOD START"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Period Start is required",
      },
      {
        rule: "dateFormat",
        value: "yyyy/MM/dd",
        errorMessage: "Date format must be yyyy/MM/dd.",
      },
      {
        rule: "validPayPeriodStartDate",
        errorMessage: "Pay Period Start Date is invalid.",
      }
    ],
  },
  {
    label: "Period End",
    key: "PeriodEnd",
    alternateMatches: ["Period End", "End", "CONTRIBUTION PERIOD END"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Period End is required.",
      },
      {
        rule: "dateFormat",
        value: "yyyy/MM/dd",
        errorMessage: "Date format must be yyyy/MM/dd.",
      },
      {
        rule: "validPayPeriodEndDate",
        errorMessage: "Pay Period End Date is invalid.",
      }
    ],
  },
  {
    label: "SIN",
    key: "SIN",
    alternateMatches: ["SIN", "sin", "S.I.N."],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "unique",
        errorMessage: "SIN must be unique.",
      },
      {
        rule: "required",
        errorMessage: "SIN is required.",
      },
      {
        rule: "validSIN",
        errorMessage: "Field must be a valid SIN.",
      },
    ],
  },
  {
    label: "Surname",
    key: "Surname",
    alternateMatches: ["surname", "name", "Surname", "SURNAME"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Surname is required.",
      },
      {
        rule: "validateEmployeeName",
        errorMessage: "Surname is invalid.",
      }
    ],
  },
  {
    label: "Givennames",
    key: "Givennames",
    alternateMatches: ["given name", "Givennames", "GIVENNAMES"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Givennames is required.",
      },
    ],
  },
  {
    label: "Employment Type",
    key: "employmenttype",
    alternateMatches: ["Employement Type", "Employment", "Work Type", "employmenttype", "EmploymentType"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Work Type is required.",
      },
      {
        rule: "validateNonCasualMemberForLtdPlan",
        errorMessage: "Casual employees do not qualify for LTD coverage/benefits, change employment type REGULAR.",
      },
    ],
  },
  {
    label: "Status",
    key: "workstatus",
    alternateMatches: ["Work Status", "work status", "Status", "status", "workstatus"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "workStatusValidationRules",
      },
      {
        rule: "workStatusValidationRules2"
      },
      {
        rule: "workStatusValidationCodes",
        errorMessage: "Work Status Code is not valid.",
      },
      {
        rule: "validateActiveEmployeeNoHours",
        warningMessage: "Employee is Active but has no hours entered. Please verify hours.",
        level: "warning",
      },
    ],
  },
  {
    label: "Status Start",
    key: "workstatusstartdate",
    alternateMatches: ["Work Status Start", "Work Start", "Status Start", "workstatusstartdate", "WorkstatusStart"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "WorkStatusStartDateIsIntheFuture",
        errorMessage: "Work Status Start Date cannot be in the future.",
      },
      {
        rule: "isWorkStatusStartAfterPrior",
        errorMessage: "The start date for the current work status must be later than the previous work start date on record.",
      },
    ],
  },
  {
    label: "Status End",
    key: "workstatusenddate",
    alternateMatches: ["Work Status End", "Work End", "Status End", "workstatusenddate", "WorkstatusEnd"],
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "WorkStatusEndDateIsIntheFuture",
        errorMessage: "Work Status End Date cannot be in the future.",
      },
      {
        rule: "WorkStatusEndDateIsBeforeStartDate",
        errorMessage: "Work Status End Date cannot be before Work Status Start Date.",
      },
      {
        rule: "validateWorkEndDateBeforePayPeriodStartDate",
        warningMessage: "Work Status End Date must be before Pay Period Start Date.",
        level: "warning",
      }
    ],
  },
  {
    label: "Pension Hours",
    key: "penhrs",
    alternateMatches: ["Pension", "Pension Hours", "penhrs", "PensionHours", " PensionHours ", "PENSION HRS", "PEN HRS" ],
    fieldType: {
      type: "input",
    },
    example: "13.5",
    validations: [
      {
        rule: "required",
        errorMessage: "Pension Hours is required.",
      },
      {
        value: "^(?:\\d+|\\d*\\.\\d+)$",
        errorMessage: "Pension Hours must be a number or decimal.",
        level: "error",
      },
      {
        rule: "minLength",
        value: 1,
        errorMessage: "Field cannot be empty.",
        level: "error",
      },
      {
        rule: "NonNegativePensionHours",
        errorMessage: "Pension Hours cannot be negative.",
      },
      {
        rule: "validPensionHours",
        errorMessage: "Pension Hours is invalid.",
        level: "error",
      },
      {
        rule: "maxPensionHours",
        errorMessage: "Pension Hours must not exceed 193 hours per pay period.",
        level: "error",
      },
      {
        rule: "hasPensionHoursContribution",
        errorMessage: "Employee does not contribute to Pension Plan. Pension Hours must be 0.",
        level: "error",
      },
      {
        rule: "over71PensionHoursContribution",
        errorMessage: "Employee is over 71 years old. Pension Hours must be 0.",
        level: "error",
      }
    ],
  },
  {
    label: "LTD Hours",
    key: "ltdhrs",
    fieldType: {
      type: "input",
    },
    alternateMatches: ["ltd", "Ltd Hours", "LTD Hours", "ltdhrs", "LTD HRS",  "LtdHours", " LtdHours "],
    example: "13.5",
    validations: [
      {
        rule: "required",
        errorMessage: "LTD Hours is required.",
      },
      {
        value: "^(?:\\d+|\\d*\\.\\d+)$",
        errorMessage: "LTD Hours must be a number or decimal.",
        level: "error",
      },
      {
        rule: "minLength",
        value: 1,
        errorMessage: "Field cannot be empty.",
        level: "error",
      },
      {
        rule: "NonNegativeLTDHours",
        errorMessage: "LTD Hours cannot be negative.",
      },
      {
        rule: "validLTDHours",
        errorMessage: "LTD Hours is invalid.",
        level: "error",
      },
      {
        rule: "hasLtdHoursContribution",
        level: "error",
      },
      {
        rule: "validateLTDHoursLessThanOrEqualToPensionHours",
        errorMessage: "LTD Hours must be less than or equal to Pension Hours.",
        level: "error",
      }
    ],
  },
];
