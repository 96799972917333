import React, { useEffect, useState } from 'react';

// AWS Amplify - API - Request Handler
import { handleGet } from '../../utils/amplifyInstance';

// Material-ui
import { Card, CardContent, Step, Stepper, StepLabel, Stack, Typography } from '@mui/material';

// Auth Context
import { useSession } from '../../context/AuthSession';

// Hooks
import useBillingOpenStatement from '../../hooks/useBillingOpenStatement';
import { useInitialExcelData } from './hooks/useInitialExcelData';

// Project Imports
import UploadFile from './steps/UploadFile/UploadFile';
import MatchColumnsFile from './steps/MatchColumnsFile/MatchColumnsFile';
import NewEmployeeData from './steps/NewEmployeeFile/NewEmployeeData';
import ValidationFile from './steps/ValidationFile/ValidationFile';
import ConfirmationFile from './steps/ConfirmationFile/ConfirmationFile';

// Utils
import { extractWorkbookData } from './utils/ExtractWorkBookData';

// Helper Functions
import { calculateDays } from '../../common/HelperFunctions';

// Icons
import WarningIcon from '@mui/icons-material/Warning';

// Third-party
import { useSnackbar } from 'notistack';

// step options
const steps = [
  'Upload file',
  'Match columns',
  'Validate data',
  'New employee data',
  'Confirm and submit',
];

const getStepContent = (
  step,
  handleNext,
  handleBack,
  errorIndex,
  setErrorIndex,
  accountid,
  companyid,
  statementid,
  roleid,
  cognito_identity_uuid,
  statementRow,
  uploadFileData,
  setUploadFileData,
  workbookData,
  setWorkBookData,
  selectedSheet,
  setSelectedSheet,
  sheetNamesArray,
  headerRowData,
  setHeaderRowData,
  employeeData,
  setEmployeeData,
  rows,
  setRows,
  employeeNewSinNumber,
  setEmployeeNewSinNumber,
  validateData,
  setValidateData,
  employeeValidationData,
  onClaimEmployeeIds,
  totalMaxHoursPerPayPeriod,
  submitData,
  setSubmitData,
  processedRows,
  setProcessedRows,
  discardedRows,
  setDiscardedRows,
  recordedRows,
  setRecordedRows,
  deletedRows,
  setDeletedRows,
  employeeActiveCheckList,
  setEmployeeActiveCheckList,
  confirmClose,
  setConfirmClose,
  handleClose,
  attachmentInfo,
  setAttachmentInfo
) => {
  switch (step) {
    case 0:
      return (
        <UploadFile
          handleNext={handleNext}
          errorIndex={errorIndex}
          setErrorIndex={setErrorIndex}
          uploadFileData={uploadFileData}
          setUploadFileData={setUploadFileData}
          workbookData={workbookData}
          setWorkBookData={setWorkBookData}
          selectedSheet={selectedSheet}
          setSelectedSheet={setSelectedSheet}
          sheetNamesArray={sheetNamesArray}
          rows={rows}
          setRows={setRows}
          headerRowData={headerRowData}
          companyid={companyid}
          cognito_identity_uuid={cognito_identity_uuid}
          setHeaderRowData={setHeaderRowData}
          setEmployeeData={setEmployeeData}
          setAttachmentInfo={setAttachmentInfo}
        />
      );
    case 1:
      return (
        <MatchColumnsFile
          handleNext={handleNext}
          accountid={accountid}
          rows={rows}
          employeeData={employeeData}
          employeeValidationData={employeeValidationData}
          statementRow={statementRow}
          companyid={companyid}
          headerRowData={headerRowData}
          setRows={setRows}
          setValidateData={setValidateData}
          setEmployeeActiveCheckList={setEmployeeActiveCheckList}
        />
      );
    case 2:
      return (
        <ValidationFile
          handleNext={handleNext}
          handleBack={handleBack}
          errorIndex={errorIndex}
          accountid={accountid}
          companyid={companyid}
          onClaimEmployeeIds={onClaimEmployeeIds}
          totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
          statementRow={statementRow}
          statementid={statementid}
          validateData={validateData}
          setValidateData={setValidateData}
          setSubmitData={setSubmitData}
          setDiscardedRows={setDiscardedRows}
          setDeletedRows={setDeletedRows}
        />
      );
    case 3:
      return (
        <NewEmployeeData
          handleNext={handleNext}
          handleBack={handleBack}
          accountid={accountid}
          companyid={companyid}
          roleid={roleid}
          statementRow={statementRow}
          submitData={submitData.validData}
          employeeNewSinNumber={employeeNewSinNumber}
          setEmployeeNewSinNumber={setEmployeeNewSinNumber}
        />
      );
    case 4:
      return (
        <ConfirmationFile
          submitData={submitData}
          setSubmitData={setSubmitData}
          uploadFileData={uploadFileData}
          accountid={accountid}
          companyid={companyid}
          statementid={statementid}
          cognito_identity_uuid={cognito_identity_uuid}
          onClaimEmployeeIds={onClaimEmployeeIds}
          totalMaxHoursPerPayPeriod={totalMaxHoursPerPayPeriod}
          deletedRows={deletedRows}
          setDeletedRows={setDeletedRows}
          employeeActiveCheckList={employeeActiveCheckList}
          statementRow={statementRow}
          processedRows={processedRows}
          setProcessedRows={setProcessedRows}
          discardedRows={discardedRows}
          recordedRows={recordedRows}
          setRecordedRows={setRecordedRows}
          confirmClose={confirmClose}
          setConfirmClose={setConfirmClose}
          attachmentInfo={attachmentInfo}
          handleClose={handleClose}
        />
      );
    default:
      throw new Error('Unknown step');
  }
};

const SpreadSheetImporterWizard = ({
  accountid,
  companyid,
  statementid,
  roleid,
  statementRow,
  cognito_identity_uuid,
  confirmClose,
  setConfirmClose,
  billingStatementOpen,
  handleClose,
}) => {
  // Auth Context
  const { authToken } = useSession();

  // States
  const [activeStep, setActiveStep] = useState(0);
  const [uploadFileData, setUploadFileData] = useState({});
  const [workbookData, setWorkBookData] = useState(null);
  const [selectedSheet, setSelectedSheet] = useState(0);
  const [sheetNamesArray, setSheetNamesArray] = useState([]);
  const [headerRowData, setHeaderRowData] = useState([]);
  const [employeeData, setEmployeeData] = useState(null);
  const [rows, setRows] = useState([]);
  const [employeeNewSinNumber, setEmployeeNewSinNumber] = useState([]);
  const [validateData, setValidateData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [employeeValidationData, setEmployeeValidationData] = useState([]);
  const [onClaimEmployeeIds, setOnClaimFilteredEmployeeIds] = useState([]);
  const [totalMaxHoursPerPayPeriod, setTotalMaxHoursPayPeriod] = useState(0);
  const [submitData, setSubmitData] = useState(null);
  const [processedRows, setProcessedRows] = useState(0);
  const [discardedRows, setDiscardedRows] = useState(0);
  const [recordedRows, setRecordedRows] = useState(0);
  const [deletedRows, setDeletedRows] = useState([]);
  const [employeeActiveCheckList, setEmployeeActiveCheckList] = useState([]);
  const [errorIndex, setErrorIndex] = useState(null);
  const [attachmentInfo, setAttachmentInfo] = useState([]);

  // notistack
  const { enqueueSnackbar } = useSnackbar();

  // Scroll to top of the page
  const scrollToTopCard = () => {
    const element = document.getElementById('topCard');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Handle next step in the wizard
  const handleNext = () => {
    setActiveStep(activeStep + 1);
    scrollToTopCard();
  };

  // Handle previous step in the wizard
  const handleBack = () => {
    setActiveStep(activeStep - 1);
    scrollToTopCard();
  };

  // Get Billing Statement Open Hook
  const { billingStatementEditable } = useBillingOpenStatement(
    billingStatementOpen,
    statementRow.status,
    statementRow.statementid
  );

  // Member Details API - Fetch and Get Members Details
  useEffect(() => {
    let cancel = false;

    const fetchData = async () => {
      try {
        let response = await handleGet({
          apiName: 'EmployerApiGw',
          path:
            '/employer/' +
            accountid +
            '/' +
            companyid +
            '/jsondoc/details/' +
            statementRow.statementid +
            '?status=' +
            statementRow.status,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const { body } = await response;
        const json = await body.json();

        if (cancel) {
          return;
        }

        response = JSON.parse(
          JSON.stringify(json, (k, v) => {
            return k === 'edits' || k === 'warnings'
              ? v == null
                ? []
                : v.split(',')
              : v == null
                ? ''
                : v;
          })
        );

        // Employee's On IWA LTD Claim.
        const filteredEmployeesOnClaimIds = response.items
          .filter((employee) => employee.warnings.includes('On LTD Claim.'))
          .map((employee) => employee.employeelistid);
        setOnClaimFilteredEmployeeIds(filteredEmployeesOnClaimIds);

        setEmployeeValidationData(response.items);
      } catch (e) {
        setEmployeeValidationData([]);
      }

      setRefresh(false);
    };

    fetchData();

    return () => {
      cancel = true;
      sessionStorage.removeItem('eeworkstatuses');
    };
  }, [refresh, accountid, companyid, authToken, statementRow.statementid, statementRow.status]);

  // Member Details API - Calculate the dates between billing period start and end dates
  useEffect(() => {
    let cancel = false;
    const { statementid, status, periodstart, periodend } = statementRow;

    if (!statementid) {
      return;
    }

    // Ensure that the status is "open", period start and end dates are defined
    if (status !== 'open' || !periodstart || !periodend) {
      return;
    }

    // Ensure that calculateDays is a function before calling it
    if (typeof calculateDays === 'function') {
      try {
        const billingStatementDays = calculateDays(periodstart, periodend);

        if (cancel) {
          return;
        }
        //  check if the billingStatementDays is a number
        if (typeof billingStatementDays === 'number') {
          const totalDays = billingStatementDays * 14;
          setTotalMaxHoursPayPeriod(totalDays);
        }
      } catch (e) {
        console.error('Error calculating days: ', e);
      }
    } else {
      console.error('calculateDays is not a function');
    }
    return () => {
      cancel = true;
    };
  }, [statementRow]);

  // Extract workbook data and get headers and Rows data
  useEffect(() => {
    async function fetchWorkbookData() {
      if (workbookData !== null) {
        try {
          if (workbookData && workbookData.SheetNames.length > 0) {
            setSheetNamesArray(workbookData.SheetNames);
          }
          const mappedData = await extractWorkbookData(
            workbookData,
            workbookData.sheetName,
            selectedSheet
          );
          if (mappedData.length > 0) {
            const headerRow = mappedData[0];
            const rowValues = mappedData.slice(1);
            if (rowValues.length === 0) {
              enqueueSnackbar('No data found in the spreadsheet.', {
                variant: 'error',
              });
            } else {
              setEmployeeData(rowValues);
            }

            const headerColumnsData = headerRow.map((columnName) => {
              let title = columnName.replace(/([A-Z])/g, '$1');
              title = title.charAt(0).toUpperCase() + title.slice(1);

              return { title, name: columnName };
            });

            setHeaderRowData(headerColumnsData);
            const rowFormattedData = rowValues.map((value) => {
              const obj = {};
              headerRow.forEach((key, index) => {
                obj[key] = value[index] !== undefined ? value[index] : '';
              });
              return obj;
            });
            setRows(rowFormattedData);
          } else {
            setHeaderRowData([]);
            setRows([]);
            enqueueSnackbar('An error occurred while extracting spreadsheet data.', {
              variant: 'error',
            });
          }
        } catch (error) {
          console.error('An error occurred while extracting workbook data:', error);
          console.log(error);
          enqueueSnackbar('An error occurred while extracting spreadsheet data.', {
            variant: 'error',
          });
        }
      }
    }

    fetchWorkbookData();
  }, [workbookData, selectedSheet, enqueueSnackbar]);

  // Check if the billing statement is editable
  useEffect(() => {
    if (billingStatementEditable === 'N') {
      setConfirmClose(true);
    } else {
      setConfirmClose(false);
    }
  }, [billingStatementEditable, setConfirmClose]);

  // Post initial excel data to the API endpoint
  const { setEnable } = useInitialExcelData(
    validateData,
    employeeActiveCheckList,
    accountid,
    companyid,
    authToken,
    statementRow.statementid
  );

  useEffect(() => {
    if (Array.isArray(validateData) && validateData.length > 0 && activeStep === 2) {
      setEnable(true);
      // Filter out empty data
      const filteredData = validateData.filter(
        (item) => item.employeelistid !== '' && item.CompanyNumber !== undefined
      );
      setProcessedRows(filteredData.length);
    }
  }, [validateData, activeStep, setEnable, setProcessedRows]);

  return (
    <Card>
      <div id="topCard" />
      <CardContent>
        {billingStatementEditable === 'Y' && (
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label, index) => {
              const labelProps = {};

              if (index === errorIndex) {
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    Error
                  </Typography>
                );

                labelProps.error = true;
              }

              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
        {billingStatementEditable === 'Y' ? (
          <>
            {getStepContent(
              activeStep,
              handleNext,
              handleBack,
              errorIndex,
              setErrorIndex,
              accountid,
              companyid,
              statementid,
              roleid,
              cognito_identity_uuid,
              statementRow,
              uploadFileData,
              setUploadFileData,
              workbookData,
              setWorkBookData,
              selectedSheet,
              setSelectedSheet,
              sheetNamesArray,
              headerRowData,
              setHeaderRowData,
              employeeData,
              setEmployeeData,
              rows,
              setRows,
              employeeNewSinNumber,
              setEmployeeNewSinNumber,
              validateData,
              setValidateData,
              employeeValidationData,
              onClaimEmployeeIds,
              totalMaxHoursPerPayPeriod,
              submitData,
              setSubmitData,
              processedRows,
              setProcessedRows,
              discardedRows,
              setDiscardedRows,
              recordedRows,
              setRecordedRows,
              deletedRows,
              setDeletedRows,
              employeeActiveCheckList,
              setEmployeeActiveCheckList,
              confirmClose,
              setConfirmClose,
              handleClose,
              attachmentInfo,
              setAttachmentInfo
            )}
          </>
        ) : (
          <>
            <Stack
              alignItems="flex-start"
              spacing={2}
              direction={{
                xs: 'column',
                sm: 'row',
              }}
            >
              <WarningIcon color="error" />
              <div>
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {
                    'You have previous open pay period(s) that need to be completed before this period can be posted.'
                  }
                </Typography>
              </div>
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default SpreadSheetImporterWizard;
